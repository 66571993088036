<style scoped>
.tag {
    margin-right: 4px;
    background: #edeff2;
    border-radius: 4px;
    font-size: x-small;
    color: #667280;
    padding: 2px 6px;
}
</style>

<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            课程管理
            <span>&gt;</span>
            设置课程《{{ course.title }}》的标签
            <button class="veadmin-iframe-button" onclick="history.back();">
                <i class="el-icon-back"></i>
            </button>
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 20px">
                <el-steps :active="1" finish-status="success" align-center style="margin-bottom: 10px">
                    <el-step title="设置课程基本信息" description="已完成"></el-step>
                    <el-step title="设置课程标签" description="进行中..."></el-step>
                    <el-step title="设置发布平台"></el-step>
                    <el-step title="设置推广"></el-step>
                </el-steps>
                <!-- <div style="padding: 10px 0px; text-align: center"> -->
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <el-button v-if="!inputVisible" style="width: 120px" size="small" @click="showInput">+ 增加标签</el-button>
                        <el-input
                            class="input-new-tag"
                            v-else
                            v-model="inputValue"
                            ref="saveTagInput"
                            size="small"
                            style="width: 120px; margin-right: 10px"
                            @keyup.enter.native="addCourseTag"
                            @blur="addCourseTag"
                        >
                        </el-input>
                        <el-button
                            size="small"
                            type="primary"
                            @click="jumpTo('/admin/course/setCoursePlatform?courseId=' + course.id + '&courseTitle=' + course.title)"
                            >下一步</el-button
                        >
                    </div>
                    <div class="text item">
                        <el-tag
                            style="margin: 4px"
                            v-for="tag in tagList"
                            :key="tag.id"
                            closable
                            :disable-transitions="false"
                            @close="deleteCourseTag(tag)"
                        >
                            {{ tag.name }}
                        </el-tag>
                        <el-result v-if="tagList.length == 0" icon="info" title="未设置标签"></el-result>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { apiListCourseTagByCourseId } from "@/api/public/courseTag.js";
import { apiAddCourseTag, apiDeleteCourseTagById } from "@/api/admin/courseTag.js";
export default {
    data() {
        return {
            course: {
                id: this.$route.query.courseId,
                title: this.$route.query.courseTitle
            },
            tagList: [],
            inputVisible: false,
            inputValue: ""
        };
    },
    methods: {
        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        addCourseTag() {
            //去除首尾空格
            let tagName = this.inputValue.trim();
            if (tagName == "") {
                //标签名为空，不提交
                this.inputVisible = false;
                this.inputValue = "";
                return;
            }
            var formData = new FormData();
            formData.append("courseId", this.course.id);
            formData.append("name", tagName);
            //调用接口 新增课程发布平台
            apiAddCourseTag(this.$store.state.token, formData).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: "成功增加！",
                        type: "success"
                    });
                    //执行成功1秒后刷新页面
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                } else {
                    console.log("response>>", response);
                    this.$message.error(response.data.message);
                }
            });
        },
        async deleteCourseTag(tag) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", tag.id);
            //调用接口
            const response = await apiDeleteCourseTagById(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                this.tagList.splice(this.tagList.indexOf(tag), 1);
            } else {
                this.$message.error(response.data.message);
            }
        },
        async listCourseTagByCourseId() {
            try {
                const response = await apiListCourseTagByCourseId(this.course.id);
                if (response.data.code == 200) {
                    this.tagList = response.data.data;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
                this.$message.error(error);
            }
        },
        jumpTo(url) {
            window.location.href = url;
        }
    },
    mounted() {
        this.listCourseTagByCourseId();
    }
};
</script>
