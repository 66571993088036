import axios from "@/api/baseRequest.js";

//增加课程标签
export const apiAddCourseTag = (token, formData) =>
    axios.post("/api/1/admin/courseTag/addCourseTag", formData, {
        headers: {
            token: token
        }
    });

//删除课程标签
export const apiDeleteCourseTagById = (formData) => axios.post("/api/1/admin/courseTag/deleteCourseTagById", formData);
