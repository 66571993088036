import axios from "@/api/baseRequest.js";

//根据courseId列出课程标签
export const apiListCourseTagByCourseId = function (courseId) {
    return axios.get("/api/1/pub/courseTag/listCourseTagByCourseId", {
        params: {
            courseId: courseId
        }
    });
};
